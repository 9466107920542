<template>
<div>
    <v-breadcrumbs :items="items">
        <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>
</div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
        },
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
</style>
