import { render, staticRenderFns } from "./BreadCrumb.vue?vue&type=template&id=2b0f21c8&scoped=true"
import script from "./BreadCrumb.vue?vue&type=script&lang=js"
export * from "./BreadCrumb.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b0f21c8",
  null
  
)

export default component.exports