<template>
<v-flex style="padding:2%">
    <v-card @click="$emit('input',category._id)" class="mx-auto" height="110">
        <v-flex text-center class="ma-3">
            <v-img :src="category.image? imagePath(category.image.path):defaultCategoryImage" height="50px" width="100%" />
        </v-flex>
        <v-flex text-center>{{category.title}}</v-flex>
    </v-card>
</v-flex>
</template>

<script>
export default {
    props: ['category'],
}
</script>

<style lang="scss" scoped>

</style>
